/* NavBar.css */

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 30;
    transition: all 0.3s ease-in-out;
    margin-bottom: 4rem;
}

.navbar.scrolled {
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}

.logo-container {
    display: flex;
    justify-content: center;
    padding: 0 3rem;
    align-items: center;
    text-align: center;
    font-weight: 600;
}
.w-32{
    height: 100px;
}

.menu-button {
    padding: 0.5rem;
    border-radius: 0.375rem;
    color: #1d4ed8;
}

.menu-button svg {
    height: 1.5rem;
    width: 1.5rem;
}

.nav-links-desktop {
    display: flex;
    gap: 1.5rem;
    padding: 1.25rem;
}

.nav-links-mobile {
    position: fixed;
    transition: transform 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    left: 0;
    width: 100%;
    height: auto;
    border-radius: 0.375rem;
    padding: 6rem;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    top: 3.5rem;
    transform: translateY(-100%);
}

.nav-links-mobile.show {
    transform: translateY(0);
}
